@import 'variables';
@import '~bootstrap/scss/bootstrap.scss';

@font-face {
  font-family: "Avenir-Roman";
  src: url("/fonts/Avenir/Avenir-Roman.ttf");
}

@font-face {
  font-family: "Avenir-Heavy";
  src: url("/fonts/Avenir/Avenir-Heavy.ttf");
}


// Note: Requested to override the automatic color choosing of bootstrap for the secondary color:
.btn-secondary {
  color: white !important;
}


hr {
  height: 2px;
  border: none;
  color:$gray-400;
  background-color:$gray-400;
}

.btn-outline {
  color: $teal;
  border: 2px solid $teal;
  border-radius:1rem;
  background: white!important;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;

  &:hover {
    color: white;
    background: $teal!important;
    border: 2px solid $teal;
    transform: scale(1.1);
  }
}

.fbButton {
  background: #3b5998;
  border: 1px solid #3b5998;
}

.form-rounded {
  border-radius: 1rem;
}

.grey-text {
  color: $gray-600;
}

.gradient-background {
  background: linear-gradient(135deg, $teal, darken($teal, 10%));
}

.plan-heading {
 color: white;
 padding-top: 80px;
 padding-bottom: 80px;
 padding-left: 25px;
 font-family: 'Avenir-Heavy' 
}

.horizontalLine {
   margin-top:0px; 
   margin-left:25px; 
   margin-right:25px; 
   height:1px; 
   background-color:$gray-400; 
}

.plan-div {
  border-radius: 2px!important;
  border: 1px solid $gray-400;
}

.backgroundRed {
  background: $red;
}

.backgroundBlue {
  background: $blue;
}

.dropzone-logo {
  border-radius: 50%;
  width: 180px;
}

.dropzone {

  width:150px;
  height: 150px;
  border: 1px solid #dedede;
  background: #f5f5f5;
  margin: auto;

  text-align: center;
  
  img {
    margin: 0 auto;
    width: 25%;
  }
  .dropzone-message {
    justify-content: center;
    align-items: center;
    color: $teal;
    font-weight: 600;
    font-size: initial;
  }
}

.react-datetime-picker__calendar {
  z-index: 10 !important;
}
.react-datetime-picker__wrapper {
  border: 0 !important;
  flex-grow: 1;
}
.react-datetime-picker {
  width: 100%;
}

.rich-text-editor {
  min-height: 280px;
}

.btn-focal {
  width: 100%;
  border-radius: 2em;
}

.btn-rounded {
  border-radius: 2em;
}

.brand-missing {
  .alert {
    margin: 5em auto;
    width: 400px;
    text-align: center;
  }
}

.nav-link {
  // text-decoration: none;

  // @include hover-focus {
  //   text-decoration: underline;
  // }
  color: black;

  &.active {
    color: $primary;
  }
}

// -------------------------  TAGS INPUT  ---------------------------------
// TODO: Clean the tag styling

div.ReactTags__tags {
  position: relative;
}
/* Styles for the input */
div.ReactTags__tagInput {
  width: 200px;
  padding: 3px;
  display: inline-block;

  
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  margin: 0;
  width: 100%;
  padding: 2px 4px;

  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);
}
/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  
  display: inline-block;
  padding: 6px;
  margin: 0 5px 0 0;

  cursor: default !important;
  
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);
}
div.ReactTags__selected a.ReactTags__remove {
  color: #9c9c9c;
  margin-left: 5px;
  cursor: pointer;
}
/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
  color: #000 !important;
  font-weight: normal !important;
  font-size: 14px !important;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: .05em .01em .5em rgba(0,0,0,.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 15px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}